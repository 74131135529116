<template>
  <div>
    <div>
      <!-- v-if="type == 'add'" -->
      <a-form-model
        :model="formInline"
        @submit="handleSubmit"
        @submit.native.prevent
        :colon="colonfalse"
        :rules="rules"
        ref="ruleForm"
      >
        <div class="title">基本信息</div>
        <a-form-model-item label="材料所属公司" prop="mBcId">
          <a-select
            v-model="formInline.mBcId"
            class="search-expand-value"
            placeholder="请选择材料所属公司"
            :disabled="type == 'edit' ? true : false"
          >
            <a-select-option
              v-for="opt in filters.GetCompanies"
              :key="opt.bc_id"
              :value="opt.bc_id"
              >{{ opt.bc_name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>

        <!-- <a-form-model-item label="材料供应商">
          <a-select
            v-model="formInline.mMsId"
            class="search-expand-value"
            placeholder="请选择材料供应商"
          >
            <a-select-option
              v-for="opt in filters.MaterialSupplier"
              :key="opt.msId"
              :value="opt.msId"
              >{{ opt.msName }}</a-select-option
            >
          </a-select>
        </a-form-model-item> -->
        <a-form-model-item label="材料编号">
          <a-tooltip placement="bottom">
            <template slot="title">
              <span>公司自定义</span>
            </template>
            <a-icon type="exclamation-circle" theme="filled" />
          </a-tooltip>
          <a-input v-model="formInline.mNumber" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item label="材料品牌">
          <!-- <a-input v-model="formInline.tmaterialBrand" placeholder="">
          </a-input> -->
          <a-select
            v-model="formInline.mMbId"
            class="search-expand-value"
            placeholder="请选择材料品牌"
          >
            <a-select-option
              v-for="opt in filters.tmaterialBrand"
              :key="opt.mbId"
              :value="opt.mbId"
              >{{ opt.mbName }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="材料类别">
          <!-- <a-input v-model="formInline.tmaterialType" placeholder=""> </a-input> -->
          <!-- <a-select
            v-model="formInline.mMtId"
            class="search-expand-value"
            placeholder="请选择材料类别"
          >
            <a-select-option
              v-for="opt in filters.tmaterialType"
              :key="opt.mtId"
              :value="opt.mtId"
              >{{ opt.mtName }}</a-select-option
            >
          </a-select> -->
          <a-tree-select
            v-model="formInline.mMtId"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="filters.tmaterialType"
            tree-default-expand-all
            :replaceFields="{
              key: 'mtId',
              title: 'mtName',
              value: 'mtId',
              children: 'children',
            }"
          >
          </a-tree-select>
        </a-form-model-item>
        <!-- <a-form-model-item label="材料型号">
          <a-input v-model="formInline.mModel" placeholder=""> </a-input>
        </a-form-model-item> -->
        <a-form-model-item label="材料名称">
          <a-input v-model="formInline.mName" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item label="计量单位">
          <a-input v-model="formInline.mUnit" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item label="销售价(元)">
          <a-input v-model="formInline.mSalesPrice" placeholder=""> </a-input>
        </a-form-model-item>
        <!-- <a-form-model-item label="成本价(元)">
          <a-input v-model="formInline.mCostPrice" placeholder=""> </a-input>
        </a-form-model-item>
       
        <a-form-model-item label="材料准备时长(h)">
          <a-input v-model="formInline.mPreTime" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item label="材料配送时长(h)">
          <a-input v-model="formInline.mDeliTime" placeholder=""> </a-input>
        </a-form-model-item> -->
        <div class="title title1">材料属性(选填)</div>
        <a-form-model-item label="重量(kg)">
          <a-tooltip placement="bottom">
            <template slot="title">
              <span>每计量单位</span>
            </template>
            <a-icon type="exclamation-circle" theme="filled" />
          </a-tooltip>
          <a-input v-model="formInline.mWeight" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item label="体积(m³)">
          <a-tooltip placement="bottom">
            <template slot="title">
              <span>每计量单位</span>
            </template>
            <a-icon type="exclamation-circle" theme="filled" />
          </a-tooltip>
          <a-input v-model="formInline.mVolume" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item label="颜色">
          <a-input v-model="formInline.mColor" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item label="规格描述">
          <a-input v-model="formInline.mDesc" placeholder=""> </a-input>
        </a-form-model-item>
        <!-- <a-form-model-item label="产地">
          <a-input v-model="formInline.mPlace" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item label="发货地">
          <a-input v-model="formInline.mSendPlace" placeholder=""> </a-input>
        </a-form-model-item> -->

        <div class="table">
          <a @click="() => addItem()">新增</a>
          <a-table
            :columns="columns"
            :data-source="data"
            bordered
            :scroll="{ x: 800 }"
          >
            <template
              v-for="col in [
                'sName',
                'msSId',
                'msMOnly',
                'msCostPrice',
                'msPreTime',
                'msDeliTime',
                'msPlace',
              ]"
              :slot="col"
              slot-scope="text, record"
            >
              <div :key="col">
                <a-select
                  placeholder="请选择"
                  v-if="col == 'msSId'"
                  v-model="record.msSId"
                  @change="(e) => handleChange(e, text, col)"
                >
                  <a-select-option
                    v-for="opt in filters.MaterialSupplier"
                    :key="opt.bc_id"
                    :value="opt.bc_id"
                    >{{ opt.bc_name }}</a-select-option
                  >
                </a-select>
                <a-input
                  placeholder="供应商材料编号"
                  v-if="col == 'msMOnly'"
                  v-model="record.msMOnly"
                  :value="text"
                />
                <a-input
                  placeholder="供应价"
                  v-if="col == 'msCostPrice'"
                  v-model="record.msCostPrice"
                  :value="text"
                />
                <a-input
                  placeholder="准备时长"
                  v-if="col == 'msPreTime'"
                  v-model="record.msPreTime"
                  :value="text"
                />
                <a-input
                  placeholder="配送时长"
                  v-if="col == 'msDeliTime'"
                  v-model="record.msDeliTime"
                  :value="text"
                />
                <a-input
                  placeholder="发货地"
                  v-if="col == 'msPlace'"
                  v-model="record.msPlace"
                  :value="text"
                />
              </div>
            </template>
            <template slot="operation" slot-scope="text, record">
              <div class="editable-row-operations">
                <span>
                  <a-icon type="plus" @click="() => addItem(record.msSId)" />

                  <a-icon
                    type="minus"
                    @click="() => deleteItem(record.msSId)"
                  />
                </span>
              </div>
            </template>
          </a-table>
        </div>
        <a-form-model-item>
          <a-button :style="{ marginRight: '8px' }" @click="onClose">
            取消
          </a-button>
          <a-button type="primary" html-type="submit"> 提交 </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { GetCompanies } from "@/api/device";
import {
  GetMaterialBrandList,
  GetMaterialTypeList,
  AddMaterial,
  UpdateMaterial,
  GetMaterialSupplier,
} from "@/api/apiJF/template";
const columns = [
  {
    title: "供应商",
    dataIndex: "msSId",
    // width: "90px",
    scopedSlots: { customRender: "msSId" },
  },
  {
    title: "供应商材料编号",
    dataIndex: "msMOnly",
    scopedSlots: { customRender: "msMOnly" },
    width: "135px",
  },
  {
    title: "供应价",
    dataIndex: "msCostPrice",
    scopedSlots: { customRender: "msCostPrice" },
    width: "90px",
  },
  {
    title: "准备时长(h)",
    dataIndex: "msPreTime",
    scopedSlots: { customRender: "msPreTime" },
    width: "110px",
  },
  {
    title: "配送时长(h)",
    dataIndex: "msDeliTime",
    scopedSlots: { customRender: "msDeliTime" },
    width: "110px",
  },
  {
    title: "发货地",
    dataIndex: "msPlace",
    scopedSlots: { customRender: "msPlace" },
    width: "90px",
  },
  {
    title: "操作",
    dataIndex: "operation",
    width: "90px",
    scopedSlots: { customRender: "operation" },
  },
];
const data = [];
export default {
  name: "MaterialEdit",
  components: {},
  props: {
    record: {
      type: Object,
    },
    type: {
      type: String,
    },
  },
  data() {
    this.cacheData = data.map((item) => ({ ...item }));
    return {
      colonfalse: false,
      rules: {
        mBcId: [
          {
            required: true,
            message: "请选择所属公司",
            trigger: "change",
          },
          // {
          //   // min: 1,
          //   // // max: 5,
          //   // message: "分公司不能为空",
          //   // trigger: "blur",
          // },
        ],
      },
      formInline: {
        mBcId: "",
      },
      formInlineEdit: {
        mBcId: "",
        mNumber: "",
        mMbId: "", //品牌id
        mMtId: "", //类别id
        // tmaterialBrand: "",
        // tmaterialType: "",
        mModel: "",
        mName: "",
        mUnit: "",
        mCostPrice: "",
        mSalesPrice: "",
        mPreTime: "",
        mDeliTime: "",
        mWeight: "",
        mVolume: "",
        mColor: "",
        mDesc: "",
        mPlace: "",
        mSendPlace: "",
        mId: "",
        mMsId: "",
      },
      filters: {
        GetCompanies: [],
        tmaterialBrand: [],
        tmaterialType: [],
        MaterialSupplier: [],
      },
      key: 0,
      data,
      columns,
      editingKey: "",
      MaterialSupplierList: {},
    };
  },
  created() {
    // console.log(this.type, this.record);
    this.data = [];
    // 获取分公司信息
    GetCompanies().then((res) => {
      this.filters.GetCompanies = res.data;
    });
    // 获取供应商
    let params = {
      type: 2,
    };
    GetCompanies(params).then((res) => {
      this.filters.MaterialSupplier = res.data;
      this.filters.MaterialSupplier.forEach((el) => {
        this.MaterialSupplierList[el.bc_id] = el.bc_name;
      });
    });
  },
  mounted() {
    console.log("record", this.record);

    if (this.type == "edit") {
      for (let key in this.formInlineEdit) {
        for (let el in this.record) {
          if (key == el) {
            this.formInlineEdit[key] = this.record[el];
          }
        }
      }
      this.formInlineEdit.mMbId =
        this.record.tmaterialBrand != "" ? this.record.tmaterialBrand.mbId : "";
      this.formInlineEdit.mMtId =
        this.record.tmaterialType != "" ? this.record.tmaterialType.mtId : "";
      this.formInline = this.formInlineEdit;
      // console.log(this.formInline);
      // console.log("record", this.record);
      this.data = this.record.tmaterialSuppliers;
      this.cacheData = this.data.map((item) => ({ ...item }));
      console.log("this.cacheData ", this.cacheData);
    }
  },
  watch: {
    "formInline.mBcId": {
      handler(newValue) {
        this.GetTmaterialBrand(newValue);
        this.GetTmaterialType(newValue);
        this.GetMaterialSupplier(newValue);
      },
    },
  },
  methods: {
    // 获取材料品牌
    GetTmaterialBrand(mbBcIds) {
      let queryParam = {
        mbBcIds,
      };
      let orderParam = ["mbBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetMaterialBrandList(params).then((res) => {
        // console.log("材料品牌", res.data);
        this.filters.tmaterialBrand = res.data;
      });
    },
    // 获取材料类别
    GetTmaterialType(mtBcIds) {
      let queryParam = {
        mtBcIds,
      };
      let orderParam = ["mtBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetMaterialTypeList(params).then((res) => {
        // console.log("材料类别", res.data);
        this.filters.tmaterialType = res.data;
      });
    },
    // 获取材料供应商
    GetMaterialSupplier(sBcIds) {
      let queryParam = {
        sBcIds,
      };
      let orderParam = ["sBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetMaterialSupplier(params).then((res) => {
        // console.log("获取材料供应商", res.data);
        // this.filters.MaterialSupplier = res.data;
        // this.filters.MaterialSupplier.forEach((el) => {
        //   this.MaterialSupplierList[el.sid] = el.splace;
        // });
        // this.dataSource = res.data;
      });
    },
    MaterialSupplierResult(a) {
      return this.MaterialSupplierList[a];
    },
    handleSubmit(e) {
      this.formInline.tmaterialSuppliers = this.data;
      console.log(this.formInline);
      // console.log(this.formInlineEdit);
      // console.log(Object.keys(this.formInline));
      // console.log(Object.keys(this.formInlineEdit));
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // alert("submit!");
          this.update();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // console.log(" this.data", this.data);
    },
    // 添加或修改
    update() {
      if (this.type == "add") {
        let params = this.formInline;
        // console.log("params", params);
        AddMaterial(params).then((res) => {
          // console.log(res);
          if (res.code == "200") {
            this.$message.success("添加成功");
            this.onClose();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (this.type == "edit") {
        let params = this.formInline;
        UpdateMaterial(params).then((res) => {
          // console.log(res);
          if (res.code == "200") {
            this.$message.success("修改成功");
            this.onClose();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    onClose() {
      this.$emit("onClose");
    },
    addItem(key) {
      console.log("addItem", key, this.formInlineEdit, this.formInline);
      // this.data.splice(key + 1, 0, {
      //   sid: "",
      //   sName: "",
      // });
      this.data.push({
        // msId: "",
        msSId: "", // 供应商id
        msMOnly: "",
        msCostPrice: "",
        msPreTime: "",
        msDeliTime: "",
        msPlace: "",
        sName: "",
        msBcId:
          this.formInlineEdit.mBcId != ""
            ? this.formInlineEdit.mBcId
            : this.formInline.mBcId, //分公司id
        msMId:
          this.formInlineEdit.mId != ""
            ? this.formInlineEdit.mId
            : this.formInline.mId,
        // 材料id msMId     msBcId   g供应商id  msSId
      });
      console.log("this.data", this.data);

      // this.data.forEach((item, index) => {
      //   item.sid = index;
      // });
      // alert(
      //   "所有的数据直接收集到data里面了，查看vue工具可以看收集的数据，给后台传参数的时候把这个数据处理一下，转换为后台需要的格式"
      // );
    },
    deleteItem(key) {
      console.log("deleteItem", key);
      if (this.data.length === 1) {
        return;
      }
      // this.data.splice(key, 1);
      // this.data.forEach((item, index) => {
      //   item.key = index;
      // });
      console.log("this.data", this.data);
      const data = [...this.data];
      this.data = data.filter((item) => item.msSId !== key);
      this.cacheData = this.data.map((item) => ({ ...item }));
    },
    handleChange(value, key, column) {
      console.log("handleChange", value, key, column);
      const newData = [...this.data];
      // console.log("newData", newData);
      const target = newData.find((item) => {
        // console.log("handleChange-target", value, item.msSId);
        return value == item.msSId;
      });
      // console.log("target", target);
      if (target) {
        target[column] = value;
        // target.tsupplier.sid = value;
        target.msPlace = this.MaterialSupplierResult(value);
        this.data = newData;
        // console.log("handleChange2", target[column]);
      }
      // console.log("this.data", this.data);
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  width: 100%;
  text-align: left;
  padding-left: 30px;
  margin: 20px 0;
  font-weight: 800;
  font-size: 16px;
}

.title1 {
  margin-top: 50px;
}
/deep/.ant-row {
  display: flex;
  width: 90%;
}
/deep/ .ant-form-item-control-wrapper {
  width: 80%;
}
/deep/ .ant-form-item-label {
  width: 200px;
}
/deep/ .ant-form {
  text-align: center;
}
/deep/ .ant-form-item-children {
  display: flex;
  // text-align: center;
  // justify-content: center;
  align-items: center;
  justify-content: right;
}

/deep/.anticon {
  margin-right: 5px;
}
.table {
  text-align: left;
}
/deep/.table .ant-form-item-control-wrapper {
  width: 100%;
  margin-left: 50px;
}
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
.editable-row-operations a {
  margin-right: 8px;
}
</style>